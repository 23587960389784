import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticatedRoute, UnauthenticatedRoute } from './AppContainers';
import CreateNewAccountForm from './authenticated/user-accounts/user-accounts-new.component';
import { fetchAllFundraisersRecursively } from './authenticated/fundraisers/fundraisers.actions';

const HubspotAppInstallCallback = React.lazy(() =>
  import(
    /* webpackChunkName: "onboarding" */ './authenticated/integrations/hubspot/app-install-callback'
  )
);
const OnboardingSteps = React.lazy(() =>
  import(/* webpackChunkName: "onboarding" */ './authenticated/onboarding')
);
const DonorsList = React.lazy(() =>
  import(/* webpackChunkName: "donors" */ './authenticated/donors')
);
const DonationsList = React.lazy(() =>
  import(/* webpackChunkName: "donations" */ './authenticated/donations')
);
const CampaignsList = React.lazy(() =>
  import(/* webpackChunkName: "campaigns" */ './authenticated/campaigns')
);
const FundraisersList = React.lazy(() =>
  import(/* webpackChunkName: "fundraiser" */ './authenticated/fundraisers')
);
const FormNamespaceContainer = React.lazy(() =>
  import('./authenticated/forms')
);
const SubscriptionsList = React.lazy(() =>
  import(
    /* webpackChunkName: "subscriptions" */ './authenticated/subscriptions'
  )
);
const AccountSettingsList = React.lazy(() =>
  import(
    /* webpackChunkName: "account-settings" */ './authenticated/account-settings'
  )
);
const IntegrationsList = React.lazy(() =>
  import(/* webpackChunkName: "integrations" */ './authenticated/integrations')
);
const StripeRedirect = React.lazy(() =>
  import(
    /* webpackChunkName: "stripe-redirect" */ './authenticated/account-settings/containers/stripe-redirect.container'
  )
);
const PaypalRedirect = React.lazy(() =>
  import(
    /* webpackChunkName: "paypal-redirect" */ './authenticated/account-settings/containers/paypal-redirect.container'
  )
);
const Referrals = React.lazy(() =>
  import(/* webpackChunkName: "referrals" */ './authenticated/referrals')
);
const TextEngagementContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "text-engagement" */ './authenticated/text-engagement/text-engagement.container'
  )
);
const Partners = React.lazy(() =>
  import(/* webpackChunkName: "partners" */ './authenticated/partners')
);
const Tasks = React.lazy(() =>
  import(/* webpackChunkName: "tools" */ './authenticated/tasks')
);
const MyProfile = React.lazy(() =>
  import(/* webpackChunkName: "profile" */ './authenticated/my-profile')
);
const Logout = React.lazy(() =>
  import(/* webpackChunkName: "logout" */ './unauthenticated/logout')
);
const UniversalSignup = React.lazy(() =>
  import(
    /* webpackChunkName: "universal_signup" */ './unauthenticated/universal/universal_signup'
  )
);
const ActivateDonorProfile = React.lazy(() =>
  import(
    /* webpackChunkName: "activate-donor-profile" */ './unauthenticated/activate-donor-profile.component'
  )
);
const SetCookie = React.lazy(() =>
  import(
    /* webpackChunkName: "set-cookie-endpoint" */ './unauthenticated/set-cookie-endpoint'
  )
);

const NoMatchPage = React.lazy(() =>
  import(
    /* webpackChunkName: "no-match" */ './library/components/no-match-page.component'
  )
);
const UnauthenticatedNoMatchPage = React.lazy(() =>
  import(
    /* webpackChunkName: "no-match" */ './unauthenticated/unauthenticated-no-match-page'
  )
);
const Dashboard = React.lazy(() =>
  import('./authenticated/dashboard/dashboard.container')
);
const Redirector = React.lazy(() => import('./authenticated/redirector'));

export const Routes = () => {
  const { selectedAccount, storeUser } = useSelector(
    state => state.accountReducer
  );
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (selectedAccount && storeUser && storeUser.Authorigin) {
      dispatch(fetchAllFundraisersRecursively(selectedAccount.id, storeUser));
    }
  }, [selectedAccount, storeUser]);

  if (isLoading) {
    return null;
  }

  return (
    <Switch>
      <AuthenticatedRoute
        path="/dashboard"
        renderSideNav
        component={Dashboard}
      />
      <AuthenticatedRoute
        path="/onboarding"
        renderSideNav={false}
        component={OnboardingSteps}
      />
      <AuthenticatedRoute
        path="/donations"
        renderSideNav
        component={DonationsList}
      />
      <AuthenticatedRoute path="/donors" renderSideNav component={DonorsList} />
      <AuthenticatedRoute
        path="/recurring_plans"
        renderSideNav
        component={SubscriptionsList}
      />
      <AuthenticatedRoute
        path="/campaigns"
        renderSideNav
        component={CampaignsList}
      />
      <AuthenticatedRoute
        path="/fundraisers"
        renderSideNav
        component={FundraisersList}
      />
      <AuthenticatedRoute
        path="/forms"
        renderSideNav
        component={FormNamespaceContainer}
      />
      <AuthenticatedRoute
        path="/text-engagement"
        renderSideNav
        component={TextEngagementContainer}
      />
      <AuthenticatedRoute
        path="/settings"
        renderSideNav
        component={AccountSettingsList}
      />
      <AuthenticatedRoute path="/partners" renderSideNav component={Partners} />
      <AuthenticatedRoute path="/tasks" renderSideNav component={Tasks} />
      <AuthenticatedRoute
        path="/referrals"
        renderSideNav
        component={Referrals}
      />
      <AuthenticatedRoute
        path="/integrations"
        renderSideNav
        component={IntegrationsList}
      />
      <AuthenticatedRoute
        path="/my_profile"
        renderSideNav
        component={MyProfile}
      />
      <AuthenticatedRoute
        exact
        path="/callback/stripe/oauth_authorize"
        renderSideNav
        component={StripeRedirect}
      />
      <AuthenticatedRoute
        exact
        path="/callback/paypal/paypal_authorize"
        renderSideNav
        component={PaypalRedirect}
      />
      <AuthenticatedRoute path="/create_account" renderSideNav>
        <CreateNewAccountForm />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path="/hubspot/app_install_callback"
        renderSideNav
        component={HubspotAppInstallCallback}
      />
      <AuthenticatedRoute path="/" exact component={Redirector} />
      {/* This is a temporary fix that should be removed as soon as we don't have more universal_login links. */}
      <AuthenticatedRoute
        path="/universal_login"
        exact
        component={Redirector}
      />
      <AuthenticatedRoute path="/login" exact component={Redirector} />
      {/* Begin UnAuthenticated Routes */}
      {
        /* Question: how we handle this? */
        <UnauthenticatedRoute
          path="/signup/referrals/:referralId"
          component={UniversalSignup}
        />
      }
      <UnauthenticatedRoute path="/logout" component={Logout} />
      <UnauthenticatedRoute
        path="/activate_donor_profile"
        component={ActivateDonorProfile}
      />
      <UnauthenticatedRoute path="/sc" component={SetCookie} />
      <UnauthenticatedRoute path="/signup" exact component={UniversalSignup} />
      <UnauthenticatedRoute
        path="/universal_signup"
        exact
        component={UniversalSignup}
      />
      {/* End UnAuthenticated Routes */}
      {isAuthenticated ? (
        <AuthenticatedRoute path="*" renderSideNav component={NoMatchPage} />
      ) : (
        <UnauthenticatedRoute
          path="*"
          renderSideNav
          component={UnauthenticatedNoMatchPage}
        />
      )}
    </Switch>
  );
};
